<template>
  <div class="activeDetail">
    <div class="headDetail">
      <back />
      <img src="../../assets/images/zhiyuan.png" alt />
    </div>

    <mescroll-vue ref="mescroll" :up="mescrollUp" @init="mescrollInit" class="mescrolllist gundong">
      <div class="wish_body">
        <div
          class="wish_list"
          v-for="(items, index) in newsDataList"
          :key="index"
          @click="enterDetails(items)"
        >
          <img
            v-if="JSON.parse(items.contributionCover)[0]"
            :src="JSON.parse(items.contributionCover)[0]"
            alt
          />
          <img v-else src="../../assets/images/default.png" alt />
          <span class="wish_content">{{ items.mainContributionTitle }}</span>
          <span class="wish_btn">查看事迹</span>
          <!-- @click="enterDetails(items)" -->
        </div>
      </div>
      <div id="empty" v-if="newsDataList.length == 0"></div>
      <loading :iscomplete="listAllData ? false : true"></loading>
    </mescroll-vue>
  </div>
</template>

<script>
import Homeapi from "@/api/home";
import loading from './loading.vue' //加载动画
//import roofPlacement from './roofPlacement.vue'
import MescrollVue from 'mescroll.js/mescroll.vue' //上拉加载，下拉刷新
export default {
  data() {
    return {
      listAllData: [], //页面数据
      newsDataList: [], //新闻列表
      mescroll: null, // mescroll实例对象
      mescrollUp: {
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          rows: 10 // 每页数据的数量
        },
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "empty", //父布局的id (1.3.5版本支持传入dom元素)
          icon: "", //图标,默认null,支持网络图
          tip: "暂无数据" //提示
        },
        htmlLoading: '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">加载中..</p>',
        htmlNodata: '<p class="upwarp-nodata">-- 到底啦 --</p>',
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        toTop: {
          src: require('../../assets/mescroll/mescroll-totop.png') // 回到顶部按钮的图片路径,支持网络图
        },
        auto: true,
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
    }
  },
  components: {
    loading,
    MescrollVue,
    // roofPlacement //置顶
  },
  watch: {
    // 如果 `question` 发生改变，这个函数就会运行
    currentdisplay: {
      handler: function (newData) {
        if (!this.listAllData) {
          if (newData == this.myIndex) {
            // this.indexListDataObain();
          }
        }
      },
      immediate: true
    }
  },
  created() {
    let that = this
    if (that.$route.query.columnId) {
      that.columnId = that.$route.query.columnId
    } else {
      that.$router.push({
        path: '/home'
      })
    }
  },
  methods: {
    enterDetails(item) {
      var link;
      if (item.contributionOtherUrl) {
        link = item.contributionOtherUrl; //链接
      } else if (item.contributionType == 'IMAGE_TYPE') {
        link = window.location.href.split("#/")[0] + "#/tabloid?id=" + item.id; //链接
      } else {
        link = window.location.href.split("#/")[0] + "#/detailsTwo?id=" + item.id; //链接
      }
      window.location.href = link;
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      let params = {
        page: page.num,
        rows: page.rows,
        appName: this.appName,
        columnId: this.columnId
      }
      Homeapi.getColumnContribution(params)
        .then(resp => {
          if (resp.success) {
            if (page.num === 1) {
              this.listAllData = resp.data;
              this.newsDataList = [];
              if (this.listAllData.hds_list) {
                this.$nextTick(() => {
                  this.InitializationSwiper();
                })
              }

            }
            if (resp.data.pageList) {
              this.newsDataList = this.newsDataList.concat(resp.data.pageList.items);
            }
          } else {
            // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
            mescroll.endErr()
          }
          this.$nextTick(() => {

            if (resp.data.pageList.items) {
              mescroll.endSuccess(resp.data.pageList.items.length);
            } else {
              console.log("ss")
              mescroll.endSuccess(0);
            }
          })
        }).catch(() => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr()
        })
    },
  }
}
</script>

<style scoped lang="less">
.activeDetail {
  position: relative;
  background: #f7f7f7;
  font-style: MicrosoftYaHei;
}
.headDetail {
  width: 100%;
  position: relative;
  height: 50vw;
  .returnContent {
    position: absolute;
    left: 10px;
    top: 36px;
    color: #fff;
  }
  img {
    width: 100%;
    height: auto;
  }
  .iconfont {
    font-size: 0.32rem;
  }
}
.mescrolllist {
  height: calc(100% - 50vw);
}
.wish_body {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  padding: 0 2.5vw;
  .wish_list {
    border-radius: 5px;
    width: 30vw;
    min-height: 35vw;
    padding: 0 2vw 10px;
    display: flex;
    background: #fff;
    flex-flow: column;
    align-items: center;
    margin-bottom: 5vw;
    position: relative;
    box-sizing: border-box;
    margin-right: 2.5vw;
    &:nth-child(3n + 3) {
      margin-right: 0px;
    }
    .wish_content {
      width: 24vw;
      margin: 2vw auto 7vw;
      font-weight: bold;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
    }
    .wish_btn {
      width: 18vw;
      background: #f61729;
      color: #fff;
      padding: 1px 5px;
      border-radius: 7px;
      font-size: 12px;
      // margin-left: 21px;
      position: absolute;
      bottom: 10px;
      text-align: center;
    }
    img {
      height: 20vw;
      border-radius: 5px;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>